import { getNameInitials } from '@mentimeter/user';
import {
  WorkspaceMemberStatusEnum,
  type GroupMemberT,
  type MemberT as WorkspaceMemberT,
} from '@mentimeter/http-clients';
import type { AvatarT, AvatarTypeT } from '@mentimeter/ragnar-ui/avatar';

export type MemberT = WorkspaceMemberT | GroupMemberT;

export const AVATAR_TYPE_BY_MEMBER_STATUS_MAP: { [key: string]: AvatarTypeT } =
  {
    [WorkspaceMemberStatusEnum.ACTIVE]: 'user',
    [WorkspaceMemberStatusEnum.PENDING]: 'pending',
    [WorkspaceMemberStatusEnum.SUSPENDED]: 'suspended',
    free: 'non-member',
    paying: 'non-member',
  };

export function transformMembersToAvatars(members: MemberT[]): AvatarT[] {
  return members?.map((member) => {
    return {
      id: String(member.id),
      itemId: String(member.id),
      initials: getNameInitials(member.name || '').toUpperCase(),
      name: member.name || member.email,
      type: AVATAR_TYPE_BY_MEMBER_STATUS_MAP[member.status] || 'user',
      profilePictureUrl: member.profile_picture_url,
    };
  });
}
